import React, { useContext } from "react";
import SummerKindnessSeriesLayout from "@src/layouts/SummerKindnessSeriesLayout";
import SEO from "@src/components/SEO";
import { graphql, useStaticQuery } from "gatsby";
import { ModalContext, ModalType } from "@src/components/modals/ModalController";
import SummerKindnessModalContent from "@src/components/partials/summer-kindness-series/SummerKindnessModalContent";
import window from "global/window";

function SummerKindnessSeriesWeek5() {
  const data = useStaticQuery(graphql`
    {
      headerImage: file(name: { eq: "strongemotions_web" }, relativePath: { regex: "/summer-kindness-series/" }) {
        childImageSharp {
          gatsbyImageData(width: 640, quality: 90, placeholder: NONE, layout: CONSTRAINED)
        }
      }
      activity1: file(name: { eq: "craftandcreate" }, relativePath: { regex: "/summer-kindness-series/" }) {
        childImageSharp {
          gatsbyImageData(width: 80, quality: 90, placeholder: NONE, layout: FIXED)
        }
      }
      activity2: file(name: { eq: "chat-circle" }, relativePath: { regex: "/summer-kindness-series/" }) {
        childImageSharp {
          gatsbyImageData(width: 80, quality: 90, placeholder: NONE, layout: FIXED)
        }
      }
      activity3: file(name: { eq: "MOJOandtheMAZE" }, relativePath: { regex: "/summer-kindness-series/" }) {
        childImageSharp {
          gatsbyImageData(width: 80, quality: 90, placeholder: NONE, layout: FIXED)
        }
      }
    }
  `);

  const { headerImage, activity1, activity2, activity3 } = data;
  const modalContext = useContext(ModalContext);
  return (
    <>
      <SEO
        title="The Summer Kindness Series - Week 5"
        description="A simple, weekly series for families to build new habits at home and inspire compassionate kids!"
        image="https://static.classdojo.com/img/summer-landing/homepage-feature-image.jpg"
        twitter={{
          card: "summary_large_image",
          site: "@classdojo",
          creator: "@classdojo",
          title: "ClassDojo's Summer Kindness Series - Week 5",
          description:
            "A simple, weekly series for families to build new habits at home and inspire compassionate kids!",
          image: "https://static.classdojo.com/img/summer-landing/homepage-feature-image.jpg",
        }}
      />
      <SummerKindnessSeriesLayout
        header="Keep Your Cool"
        headerSub="Kindness Habit 5:"
        headerText="Manage strong emotions"
        headerImage={headerImage}
        whatisIt="When temperatures reach epic highs, chances are you turn up the air conditioning or pour yourself an icy drink. For kids, strong emotions can feel just as overwhelming and uncomfortable as a hot summer day. The good news is they can beat the emotional heat by learning calming techniques! Feeling cooler already?"
        whyIsItImportant="One of the most important elements of empathy is recognizing your own emotions and knowing what to do about them. For kids, emotions can sometimes come in too hot and be hard to manage. When kids learn how to keep their cool, they’re better able to consider other perspectives. So let’s get that inner air conditioning going! Everyone likes a cool breeze :)"
        habbits={[
          {
            header: "Model calmness:",
            text: "Feeling overwhelmed yourself? Practice calming strategies in front of your child while narrating the steps you’re taking. It will help them develop their own process!",
          },
          {
            header: "Just breathe:",
            text: "There are many fantastic breathing techniques that can be used in any situation or place. Buddy Breathing, Belly Breathing, and blowing out the “candles” on each of your fingertips are wonderful techniques to practice.",
          },
          {
            header: "Create a quiet zone:",
            text: "Make a safe and cozy space in your home to calm down when emotions run hot. Remember, the quiet zone is a place of positivity, not punishment!",
          },
          {
            header: "Get to know your strategies:",
            text: "Everyone calms down in unique ways. Talk about the different techniques everyone in your family uses so you can support each other when it’s needed.",
          },
        ]}
        activitiesText="Practice Keeping Your Cool at home :) These are ideas to inspire new traditions. Did you know when kids are asked to teach it makes ideas more
              memorable?"
        activities={[
          {
            icon: activity1,
            header: "Mindfulness Jars",
            text: "Shake it up and breathe!",
            onClick: () => {
              modalContext.showModal(ModalType.SummerKindnessModal, {
                content: (
                  <SummerKindnessModalContent
                    header1="Mindfulness Jars"
                    text1="A Mindfulness Jar can be used in times of heightened emotions of any kind! Watch the video for instructions!"
                    text2={
                      <>
                        Activity Type: Craft & Create
                        <br />
                        Play Time: 30 min
                      </>
                    }
                    header2="Keep Your Cool"
                    items={[
                      { youtubeUrl: "https://www.youtube.com/embed/Q4XB4IQVg9Q" },
                      {
                        href: "https://static.classdojo.com/img/2018-summer_kindness/Week5_MindfulnessJar.pdf",
                        text: "Activity PDF",
                        icon: "pdf",
                      },
                    ]}
                  />
                ),
              });
            },
          },
          {
            icon: activity2,
            header: "Breathe In, Breathe Out",
            text: "Keep aware with your breath.",
            onClick: () => {
              modalContext.showModal(ModalType.SummerKindnessModal, {
                content: (
                  <SummerKindnessModalContent
                    header1="Breathe In, Breathe Out"
                    text1="This activity is a week long challenge to try seven diferent breathing techniques!"
                    text2={
                      <>
                        Activity Type: Family Time
                        <br />
                        Play Time: 5 min a day!
                      </>
                    }
                    header2="Keep Your Cool"
                    items={[
                      {
                        href: "https://static.classdojo.com/img/2018-summer_kindness/Week5_Breathing_activity.pdf",
                        text: "Activity PDF",
                        icon: "pdf",
                      },
                      {
                        href: "https://static.classdojo.com/img/2018-summer_kindness/BreathingCards_week5.pdf",
                        text: "Breathing Cards",
                        icon: "template",
                      },
                    ]}
                  />
                ),
              });
            },
          },
          {
            icon: activity3,
            header: "Mojo Meets The Beast",
            text: "Watch these videos and discuss!",
            onClick: () => {
              window.location.href = "https://static.classdojo.com/img/2018-summer_kindness/Mindfulness_Videos.pdf";
            },
          },
        ]}
      />
    </>
  );
}

export default SummerKindnessSeriesWeek5;
